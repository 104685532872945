import Card from "components/Card/Card.jsx";
import React from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import defaults from "../../defaults";
import FilterForm from "../Form/FilterForm";
import Loader from "../Loader/Loader";
import List from "../../components/List/List";

let g = defaults.userGroups;
export let defaultGroups = [g.admin, g.editor];

class DoubleList extends List {

  render() {
    // Define
    let actionButtons = this.props.actions || this.actionButtons;
    let headerButtons =
      this.props.headerButtons ||
      (this.props.addPath ? this.headerButtons : []);

    // Filter
    let actionButtonsFiltered = this.filterActions(actionButtons);
    let headerButtonsFiltered = this.filterActions(headerButtons);

    // Disable Actions row and header buttons?
    let isDisableActions =
      this.props.disableActions || actionButtonsFiltered.length === 0;
    let isDisableHeaderButtons =
      this.props.disableHeaderButtons || headerButtonsFiltered.length === 0;

    let filter;
    if (this.props.filterObject !== undefined) {
      filter = (
        <FilterForm
          object={this.props.filterObject}
          onSubmit={this.handleFilterSubmit}
        />
      );
    }

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.props.name + " List"}
                content={
                  <div>
                    <div className="btn-toolbar">
                      {isDisableHeaderButtons
                        ? undefined
                        : headerButtonsFiltered.map((component, index) => {
                            return React.cloneElement(component(this), {
                              key: index,
                            });
                          })}
                    </div>
                    {filter}
                  </div>
                }
              />
              <Card
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Loader isLoading={this.state.isLoading}>
                    <Table striped hover>
                      <thead>
                        <tr>
                          {this.props.male_model.map((prop, key) => {
                            return <th key={key}>{prop.col}</th>;
                          })}
                          {isDisableActions ? undefined : <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                {this.props.male_model.map((prop, index) => {
                                  return (
                                    <td key={index}>
                                      {prop.normalizer === undefined
                                        ? data[prop.row] || "-"
                                        : prop.normalizer(data)}
                                    </td>
                                  );
                                })}
                                {isDisableActions ? undefined : (
                                  <td className="table-actions">
                                    <div className="btn-toolbar">
                                      {actionButtonsFiltered.map(
                                        (component, index) => {
                                          return React.cloneElement(
                                            component(this, data),
                                            { key: index }
                                          );
                                        }
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                {this.props.female_model.map((prop, index) => {
                                  return (
                                    <td key={index}>
                                      {prop.normalizer === undefined
                                        ? data[prop.row] || "-"
                                        : prop.normalizer(data)}
                                    </td>
                                  );
                                })}
                                {isDisableActions ? undefined : (
                                  <td className="table-actions">
                                    <div className="btn-toolbar">
                                      {actionButtonsFiltered.map(
                                        (component, index) => {
                                          return React.cloneElement(
                                            component(this, data),
                                            { key: index }
                                          );
                                        }
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                    {this.state.pagination_meta && (
                      <Row>
                        <Col md={12}>
                          <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pagination_meta.total_pages}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            forcePage={this.state.pagination_meta.page - 1}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </Col>
                      </Row>
                    )}
                  </Loader>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DoubleList;
