import { buildSelector, constToSelectOptions } from "../utils/Utils";
import { API } from "aws-amplify";
import { components } from "react-select";
import React from "react";

export const ExerciseModel = (constants, exerciseLoadOptions, exercises_data, tipsLoadOptions) => {
	let tipsCache = {}
	return {
		name: {
			type: 'input',
			validationRules: 'required',
			value: '',
		},

		preview: {
			type: 'input',
			validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }, 'required'],
			value: null,
		},

		media_url: {
			type: 'input',
			validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }],
			value: null,
		},
		symmetry: buildSelector(constToSelectOptions(constants, 'symmetry'), 'select', undefined, undefined, 4),
		base_pace: {
			inputType: 'number',
			value: '',
			validationRules: 'required',
			requestNormalizer: (data) => {
				return parseFloat(data);
			}
		},
		base_mets: {
			inputType: 'number',
			value: '',
			validationRules: 'required',
			requestNormalizer: (data) => {
				return parseFloat(data);
			}
		},
		tips: get_exercise_field("multiSelect", tipsCache, tipsLoadOptions, 12, "/admin/exercises/tips", TipOption),
		instructions: {
			type: 'collection',
			value: [],
			prototype: ExerciseInstructionModel(),
			onChangeEvent: 'handleCollection',
			requestNormalizer: (data) => {
				return data.map(obj => obj.name);
			},
		},
	};
}

export const ExerciseInstructionModel = () => {
	return {
		name: {
			type: 'input',
			value: null,
		}
	}
}

export const ExerciseFilter = (constants, exercises_data) => {
	return {
		ids: {
			type: 'input',
			value: '',
			placeholder: 'Ids',
			requestNormalizer: (data) => {
				return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
			},
		},
		name: {
			type: 'input',
			value: '',
		},
	};
};


export const ExerciseOption = ({ children, ...props }) => {
	let option = props.data;

	return (
		<components.Option {...props}>
			<div className="clearfix">
				<div className="mb-3 pull-left">
					<img
						alt={"Preview"}
						className="pull-left mr-2 img-rounded"
						width="128"
						height="128"
						src={option.preview}
					/>
				</div>
				<div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
					<p style={{ margin: "0" }}>
						<strong>{`[${option.id}] ${option.name}`}</strong>
					</p>
					<p style={{ "margin": "0" }}>{`Base Mets: ${option.base_mets}`}</p>
					<p style={{ "margin": "0" }}>{`Base Pase: ${option.base_pace}`}</p>
				</div>
			</div>
		</components.Option>
	);
}

export const TipOption = ({ children, ...props }) => {
	let option = props.data;

	return (
		<components.Option {...props}>
			<div className="clearfix">
				<div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
					<p style={{ margin: "0" }}>
						<strong>{`[${option.id}] ${option.name}`}</strong>
					</p>
				</div>
			</div>
		</components.Option>
	);
}


function get_exercise_field(type, objCache, loadOptions, md, url, Option) {
	return {
		type: type,
		onChangeEvent: 'handleSelect',
		value: '',
		requestNormalizer: (data) => {
			if (!data) {
				return []
			}
			return data.map((obj) => {
				return parseInt(obj.id);
			})
		},
		responseNormalizer: (data) => {
			if (data.length === 0) {
				return
			}
			let queryParams = { "ids": JSON.stringify(data) };
			return API.get('admin', url, {
				queryStringParameters: queryParams,
			})
				.then((response_data) => {
					let objs = response_data.items.reduce(function (obj, item) {
						obj[item.id] = item;
						objCache[item.id] = item;
						return obj;
					}, {});

					return data.map(item_id => objs[item_id]);

				}).catch(error => {
					console.error(error)
					return {}
				})
		},
		selectProps: {
			components: { Option },
			getOptionLabel: option => {
				return `[${option['id']}]; ${option['name']};`
			},
			getOptionValue: option => {
				return option['id']
			},
			pageSize: 10,
			minMenuHeight: 300,
			maxMenuHeight: 1000,
			loadOptions: loadOptions,
		},
		md: md,
		loadLoadOptionEverytime: true,
	}
}
