import React, {Component} from "react";

import List from "components/List/List";
import {BaseFilter} from "models/Base";
import {ApiConstantsContext} from "variables/ApiConstantsContext";
import {WarmWelcomeFilter} from "../../models/WarmWelcome";

export default class WarmWelcome extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = "/admin/warm-welcome/add";
        this.editPath = "/admin/warm-welcome/edit";
    }

    render() {
        return (
            <List
                name="WarmWelcome"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/warm-welcome"
                listApiUrl="/admin/warm-welcome"
                model={[
                    { col: "Id", row: "id" },
                    {
                        col: "Preview",
                        row: "preview",
                        normalizer: (data) => <img alt={data.name} src={data.preview} width="100" />
                    },
                    {
                        col: "Priority",
                        row: "priority"
                    },
                    {
                        col: "System name",
                        row: "system_name"
                    },
                    {
                        col: "Branch",
                        row: "branch",
                    },
                    {
                        col: "Equipment",
                        row: "equipment",
                        normalizer: (data) => {
                            return data.equipment.map((equipment, i) =>
                                <div key={i}>
                                    {equipment}
                                </div>)
                        },
                    },
                    {
                        col: "Goals",
                        row: "goal",
                        normalizer: (data) => {
                            return data.goal.map((goal, i) =>
                                <div key={i}>
                                    {goal}
                                </div>)
                        },
                    },
                    {
                        col: "Genders",
                        row: "gender",
                        normalizer: (data) => {
                            return data.gender.map((gender, i) =>
                                <div key={i}>
                                    {gender}
                                </div>)
                        },
                    },
                    {
                        col: "Training locations",
                        row: "training_location",
                        normalizer: (data) => {
                            return data.training_location.map((training_location, i) =>
                                <div key={i}>
                                    {training_location}
                                </div>)
                        },
                    },

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={WarmWelcomeFilter()}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }
}
