import { selector, multiSelector, enumToOptions } from "inputs/selector";
import { mapString, mapNullString, mapBool } from "inputs/utils";


const WorkoutIdModel = (data) => {
    return {
        workout_id: {
            type: "number",
            validationRules: "required",
            requestNormalizer: parseInt,
        },
        challenge_id: {
            hidden: true,
        },
    }
}


const mapWorkoutLink = ({ workout_id }, i) => {
    return {
        challenge_id: -1,
        workout_id: workout_id,
        position: i,
    }
}

const removeOtherGenderField = (genderObj) => {
    return Object.keys(genderObj).length > 0
        ? (({ other, ...filteredGenderObj }) => filteredGenderObj)(genderObj)
        : genderObj;
};


export const ChallengeModel = (constants, workout_links) => {
    return {
        name: {
            type: "input",
            validationRules: "required",
        },
        description: {
            type: "input",
        },
        is_active: selector(
            { "true": "Yes", "false": "No" },
            mapBool,
            { validationRules: "required" },
        ),
        position: {
            type: "number",
            requestNormalizer: (value) => parseInt(value || 999),
        },
        preview: {
            type: "input",
            validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }],
        },
        workout_preview: {
            type: "input",
            validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }],
        },
        female_preview: {
            type: "input",
            validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }],
        },
        female_workout_preview: {
            type: "input",
            validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }],
        },
        branch_name: {
            type: "input",
            responseNormalizer: (value) => value || "",
        },
        fitness_level: multiSelector(
            enumToOptions(constants, "fitness_level"),
            mapString,
            { validationRules: "required" },
        ),
        gender: multiSelector(
            removeOtherGenderField(
                enumToOptions(constants, "gender")
            ),
            mapString,
            { validationRules: "required" },
        ),
        duration: {
            type: "number",
            requestNormalizer: parseInt,
        },
        equipment: multiSelector(
            enumToOptions(constants, "wb_equipment"),
            mapString,
        ),
        feature: selector(
            enumToOptions(constants, "user_feature"),
            mapNullString,
        ),
        workout_links: {
            type: "collection",
            value: workout_links,
            prototype: WorkoutIdModel(),
            onChangeEvent: "handleCollection",
            requestNormalizer: (items) => items.map(mapWorkoutLink),
        },
    }
}
