import {URL_BASE} from "./lib";
import {BaseFilter} from "../../../models/Base";
import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class UserTargetAreaTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "User Target Area Translations";
        this.addPath = `${URL_BASE}/add`;
        this.editPath = `${URL_BASE}/edit`;
        this.removeUrl = "";
        this.listApiUrl = URL_BASE;
    }

    generateFilter = () => {
        return BaseFilter();
    };

    generateModel() {
      let constants = this.context.constants;
      let languages = Object.values(
        constants.language === undefined ? {} : constants.language.items || {}
      );
      languages.sort((a, b) => a.value - b.value);
      let model = [{ col: "Id", row: "id" }, { col: "System Name", row: "system_name" }];
      for (const lang of languages) {
        model.push({
          col: lang.title,
          row: "languages",
          normalizer: (data) => {
            return this.generateAddEditLink(
              data.translations[lang.key],
              data.id,
              lang
            );
          },
        });
      }
      return model;
    }
}