import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import { WarmWelcomeTranslationModel } from "../../../models/WarmWelcomeTranslation";

export default class WarmWelcomeTranslationAdd extends BaseAddEditComponent {
  constructor(props) {
    super(props);
    this.name = "WarmWelcome Translation";
    this.url = "/admin/warm-welcome/translations";
    this.skipPrepopulation = true;
  }

  locationState = () => {
    return this.props.location.state || { language: {}, translation: {} };
  };

  getTitle = () => {
    return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${
      this.locationState().language.title
    }`;
  };

  getEntity = () => {
    let state = this.locationState();
    let translation = state.translation || {};
    return WarmWelcomeTranslationModel(
        state.resource || null,
       state.language.key || null,
       translation.plan_title || null,
   translation.plan_overview || null,
        translation.week_1 || null,
        translation.week_2 || null,
        translation.week_3 || null,
        translation.week_4 || null,
    );
  };
}
