export const AudioTrackTranslationModel = (
    universal_audio_track_id,
    language,
    name,
    url,
    gender,
) => {
    return {
        universal_audio_track_id: {
            value: universal_audio_track_id,
            hidden: true,
        },
        language: {
            value: language,
            hidden: true,
            responseNormalizer: (value) => {return language},
        },
        text: {
            type: "input",
            validationRules: "required",
            value: name,
        },
        url: {
            type: "input",
            validationRules: "required",
            value: url,
        },

		gender: {
            value: gender,
            hidden: true
        }
    };
};
