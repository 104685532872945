export const WbWorkoutTypeTranslationModel = (
    workout_type_id,
    language,
    name,
) => {
    return {
        workout_type_id: {
            value: workout_type_id,
            hidden: true,
        },
        language: {
            value: language,
            hidden: true,
        },
        name: {
            type: "input",
            validationRules: "required",
            value: name,
        },
    };
};
