import { WarmWelcomeModel } from "models/WarmWelcome";
import BaseAddEditComponent from "controllers/Generic/BaseAddEditComponent";


export default class WarmWelcomeAdd extends BaseAddEditComponent {

    constructor(props) {
        super(props);
        this.name = "WarmWelcome";
        this.url = "/admin/warm-welcome";
        this.redirectUrl = "/admin/warm-welcome"
    }

    getEntity = () => {
        return WarmWelcomeModel(this.context.constants)
    }
}