
export const WarmWelcomeTranslationModel = (
  warm_welcome_id,
  language,
  plan_title,
  plan_overview,
  week_1,
  week_2,
  week_3,
  week_4,
) => {
  return {
    warm_welcome_id: {
      value: warm_welcome_id,
      hidden: true,
    },
    language: {
      value: language,
      hidden: true,
    },
    plan_title: {
      type: "input",
      validationRules: "required",
      value: plan_title,
    },
    plan_overview: {
      type: "input",
      validationRules: "required",
      value: plan_overview,
    },
    week_1: {
      type: "input",
      validationRules: "required",
      value: week_1,
    },
    week_2: {
      type: "input",
      validationRules: "required",
      value: week_2,
    },
    week_3: {
      type: "input",
      validationRules: "required",
      value: week_3,
    },
    week_4: {
      type: "input",
      validationRules: "required",
      value: week_4,
    },
  };
};

export const WarmWelcomeTranslationFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    system_name: {
      type: "input",
      value: ""
    }

  };
};
