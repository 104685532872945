export const UserTargetAreaTranslationModel = (
    target_area_id,
    language,
    name,
) => {
    return {
        target_area_id: {
            value: target_area_id,
            hidden: true,
        },
        language: {
            value: language,
            hidden: true,
        },
        name: {
            type: "input",
            validationRules: "required",
            value: name,
        },
    };
};
