import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";

import { URL_BASE } from "./lib";
import {WbWorkoutTypeTranslationModel} from "../../../models/WbWorkoutTypeTranslation";

export default class WbWorkoutTypeTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "WB-WorkoutType Translation";
        this.url = URL_BASE;
        this.skipPrepopulation = true;
    }

    locationState = () => {
        return this.props.location.state || { language: {}, translation: {} };
    };

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`;
    };

    getEntity = () => {
        let state = this.locationState();
        let translation = state.translation || {};

        return WbWorkoutTypeTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || "",
        );
    };
}
