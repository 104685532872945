import React, { Component } from "react";

import List, {defaultGroups} from "components/List/List";
import { BaseFilter } from "models/Base";
import { ApiConstantsContext } from "variables/ApiConstantsContext";
import API from "@aws-amplify/api";
import ImportButton from "../../components/List/ImportButton";

export default class ContentfulSync extends Component {
    static contextType = ApiConstantsContext;

    runContentfulSync = () => {
        API.post("admin", "/admin/contentful-sync")
            .then(() => { alert("Contentful sync started") })
            .catch(() => { alert("Failed with error - check logs") })
    }
    render() {
        let headerButtons = [
            (list) => {
                return <ImportButton
                    allowedGroups={list.props.allowedAdd || defaultGroups}
                    onConfirmed={this.runContentfulSync}
                    text="Sync"
                />
            }
        ]
        return (
            <List
                name="Contentful Sync"
                listApiUrl="/admin/contentful-sync"
                model={[
                    { col: "Id", row: "id" },
                    { col: "Status", row: "status" },
                    { col: "Log", row: "log" },
                ]}
                disableActions={true}
                filterObject={BaseFilter()}
                userHasPermission={this.props.userHasPermission}
                headerButtons={headerButtons}
            >
            </List>
        );
    }
}