import {enumToOptions, multiSelector} from "../inputs/selector";
import {mapString} from "../inputs/utils";

export const WarmWelcomeModel = (constants) => {
	return {
		priority: {
 			type: "number",
            requestNormalizer: parseInt,
			validationRules: "required"
		},
		system_name: {
			type: "input",
			validationRules: "required"
		},
		preview: {
			type: 'input',
			validationRules: [{ 'regex': "^https://mobile.appscdn.io/.*" }, 'required'],
			value: null,
		},
		branch: {
			type: "input",
		},
		equipment: multiSelector(
            enumToOptions(constants, "wb_equipment"),
            mapString,
        ),
		goal: multiSelector(
            enumToOptions(constants, "goal"),
            mapString,
        ),
		gender: multiSelector(
            enumToOptions(constants, "gender"),
            mapString,
        ),
		training_location: multiSelector(
            enumToOptions(constants, "training_location"),
            mapString,
        ),
		plan_title: {
		  type: "input",
		  validationRules: "required",
		  value: null,
		},
		plan_overview: {
		  type: "input",
		  validationRules: "required",
		  value: null,
		},
		week_1: {
		  type: "input",
		  validationRules: "required",
		  value: null,
		},
		week_2: {
		  type: "input",
		  validationRules: "required",
		  value: null,
		},
		week_3: {
		  type: "input",
		  validationRules: "required",
		  value: null,
		},
		week_4: {
		  type: "input",
		  validationRules: "required",
		  value: null,
		},

	}
}
export const WarmWelcomeFilter = () => {
  return {
    ids: {
      type: "input",
      value: "",
      placeholder: "Ids",
      requestNormalizer: (data) => {
        return JSON.stringify(
          Array.from(data.matchAll(/\d+/g), (m) => parseInt(m[0]))
        );
      },
    },
    system_name: {
      type: "input",
      value: "",
    },
  };
};