import BaseTranslationComponent from "controllers/Generic/BaseTranslationComponent";
import {BaseFilter} from "models/Base";

import {URL_BASE} from "controllers/Translation/AudioTrack/lib";
import {NavLink} from "react-router-dom";
import React from "react";
import DoubleList from "../../../components/List/DoubleList";

export default class AudioTrackTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Audio Track Translations";
        this.addPath = `${URL_BASE}/add`;
        this.editPath = `${URL_BASE}/edit`;
        this.removeUrl = "";
        this.listApiUrl = URL_BASE;
    }

    generateAddEditLink = (translations, resource, language, gender) => {
        let genders = {"female": 1, "male": 2}
        const defaultText = "--";
        if (translations !== undefined) {
            if(translations[0].gender===genders[gender]){
                const isTextBlank = !translations[0].name;
                return (
                      <NavLink
                        to={{
                          pathname: this.editPath + "/" + translations[0].id,
                          state: {
                            gender: gender,
                            resource: resource,
                            language: language,
                            translation: translations[0],
                          },
                        }}
                        style={{ color: isTextBlank ? "red" : "#1DC7EA" }}
                      >
                        {isTextBlank ? defaultText : translations[0].name}
                      </NavLink>
                      );
            }
            if(translations.length>1 && translations[1].gender===genders[gender]){
                const isTextBlank = !translations[1].name;
                return (
                        <NavLink
                            to={{
                              pathname: this.editPath + "/" + translations[1].id,
                              state: {
                                gender: gender,
                                resource: resource,
                                language: language,
                                translation: translations[1],
                              },
                            }}
                            style={{ color: isTextBlank ? "red" : "#1DC7EA" }}
                          >
                            {isTextBlank ? defaultText : translations[1].name}
                        </NavLink>
                      );
            }
        }
        return (
          <NavLink
            to={{
              pathname: this.addPath,
              state: { resource: resource, language: language, gender: gender },
            }}
            style={{ color: "red" }}
          >
            Add
          </NavLink>
        );
  };
  generateModel(gender) {
    let constants = this.context.constants;
    let languages = Object.values(
      constants.language === undefined ? {} : constants.language.items || {}
    );
    languages.sort((a, b) => a.value - b.value);
    let model = [
        { col: "Id", row: "id" },
        {col: "Gender", row: "gender", normalizer: () => {
            return gender
            }},
    ];
    for (const lang of languages) {
      model.push({
        col: lang.title,
        row: "languages",
        normalizer: (data) => {
          return this.generateAddEditLink(
            data.translations[lang.key],
            data.id,
            lang,
            gender
          );
        },
      });
    }
    return model;
  }
    render() {
    return (
      <DoubleList
        name={this.name}
        disableActions
        editPath={this.editPath}
        removeUrl={this.removeUrl}
        listApiUrl={this.listApiUrl}
        male_model={this.generateModel("male")}
        female_model={this.generateModel("female")}
        onRemove={this.onRemove}
        handleClick={this.props.handleClick}
        filterObject={this.generateFilter()}
        userHasPermission={this.props.userHasPermission}
      >
      </DoubleList>
    );
  }
    generateFilter = () => {
        return BaseFilter();
    };
}
