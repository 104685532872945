import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";
import {WarmWelcomeTranslationFilter} from "../../../models/WarmWelcomeTranslation";
import {NavLink} from "react-router-dom";
import React from "react";

export default class WarmWelcomeTranslation extends BaseTranslationComponent {
  constructor(props) {
    super(props);
    this.name = "WarmWelcome Translations";
    this.addPath = "/admin/warm-welcome/translations/add";
    this.editPath = "/admin/warm-welcome/translations/edit";
    this.removeUrl = "/admin/warm-welcome/translations";
    this.listApiUrl = "/admin/warm-welcome/translations";
  }
  generateAddEditLink = (translation, resource, language) => {
    if (translation !== undefined) {
      const isPlanTitleBlank = !translation.plan_title;
      return (
        <NavLink
          to={{
            pathname: this.editPath + "/" + translation.id,
            state: {
              resource: resource,
              language: language,
              translation: translation,
            },
          }}
        >
          {isPlanTitleBlank ? "-" : translation.plan_title}
        </NavLink>
      );
    }
    return (
      <NavLink
        to={{
          pathname: this.addPath,
          state: { resource: resource, language: language },
        }}
        style={{ color: "red" }}
      >
        Add
      </NavLink>
    );
  };


    generateModel() {
    let constants = this.context.constants;
    let languages = Object.values(
      constants.language === undefined ? {} : constants.language.items || {}
    );
    languages.sort((a, b) => a.value - b.value);
    let model = [{ col: "Id", row: "id" }, {col: "System name", row: "system_name", }];
    for (const lang of languages) {
      model.push({
        col: lang.title,
        row: "languages",
        normalizer: (data) => {
          return this.generateAddEditLink(
            data.translations[lang.key],
            data.id,
            lang
          );
        },
      });
    }
    return model;
  }

  generateFilter = () => {
    return WarmWelcomeTranslationFilter();
  };
}
